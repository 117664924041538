import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../../components/seo";

import ddgArt from "../../images/discussion-guide/personalize/ddg-art.svg";
import conversationImg from "../../images/discussion-guide/personalize/conversation-plan-thumb.webp";

import "../../sass/_orphan.scss";
import "./personalize.scss";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintPdf from "../../components/print";
import { ImSpinner } from "react-icons/im";
import { navigate } from "gatsby";
import { TfiAngleRight, TfiAngleLeft } from "react-icons/tfi";

import { BsDownload } from "react-icons/bs";

const emailModule = require("raw-loader!./_email.html");
const emailTemplateString = emailModule.default;

const Q8 = [
    "How does WAKIX work?",
    "Is WAKIX a stimulant?",
    "Is WAKIX a controlled substance?",
    "How is WAKIX taken?",
    "How long might it take for WAKIX to work?",
    "What are the possible side effects of WAKIX?",
    "Do you think WAKIX might be a treatment option for me?",
];
const Q1 = [
    "Makes it harder to maintain relationships or a social life",
    "Creates challenges when it comes to work or school",
    "Affects my daily activities or routine",
    "Other",
];
const Q5 = [
    "Not achieving my treatment goals",
    "Having a hard time finding what works for me",
    "Concerned about stimulants or the potential for abuse",
    "Looking for a dosing schedule that works for me",
    "Wondering about side effects",
    "I have no concerns at the current time",
    "Other",
];

const PersonalizePage = () => {
    // const deviceSizes = useDeviceSize();
    const [data, setData] = React.useState({});
    const [step, setStep] = React.useState(0);
    const componentRef = React.useRef();
    const [emailMyself, setEmailMyself] = React.useState(false);
    const [emailSent, setEmailSent] = React.useState(false);
    const [Q1_A4_other, setQ1_A4_other] = useState("");
    const [Q5_A7_other, setQ5_A7_other] = useState("");
    const [emailSending, setEmailSending] = useState(false);
    const [pdfDownloading, setPdfDownloading] = useState(false);

    const {
        handleSubmit: handleSubmit1,
        register: register1,
        watch: watch1,
        control: control1,
        formState: { errors: errors1 },
    } = useForm({
        defaultValues: { "step-1-q-1": [] },
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit2,
        register: register2,
        watch: watch2,
        control: control2,
        formState: { errors: errors2 },
    } = useForm({
        defaultValues: { "step-2-q-1": [] },
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit3,
        register: register3,
        formState: { errors: errors3 },
    } = useForm({
        defaultValues: { "step-3-q-1": [] },
        mode: "onBlur",
    });
    const {
        handleSubmit: handleSubmit4,
        register: register4,
        watch: watch4,
        formState: { errors: errors4 },
    } = useForm({
        mode: "onBlur",
    });

    const handleNextStep = (nextStep, d) => {
        navigate("/discussion-guide/personalize/#");
        setData({ ...data, ...d });
        setStep(nextStep);
    };
    const handleBackStep = (backStep) => {
        setStep(backStep);
        navigate("/discussion-guide/personalize/#");
    };

    const handleEmailMyself = () => {
        setEmailMyself(!emailMyself);
    };
    const handleSendEmail = async (d) => {
        setEmailSending(true);
        let finalData = {};
        Q1.forEach((item, index) => {
            finalData[`Q1_A${index + 1}`] = data["step-1-q-1"].includes(item);
        });
        Q5.forEach((item, index) => {
            finalData[`Q5_A${index + 1}`] = data["step-2-q-1"].includes(item);
        });
        Q8.forEach((item, index) => {
            finalData[`Q8_A${index + 1}`] = data["step-3-q-1"].includes(item);
        });
        finalData.Q2_A = data["step-1-q-2"];
        finalData.Q3_A = data["step-1-q-3"];
        finalData.Q4_A = data["step-1-q-4"];
        finalData.Q6_A = data["step-2-q-2"];
        finalData.Q7_A = data["step-2-q-3"];
        finalData = { ...finalData, Q1_A4_other, Q5_A7_other };

        const urlEncodedParams = encodeURIComponent(JSON.stringify(finalData));
        const htmlPageToConvert =
            window.location.origin +
            "/discussion-guide/pdf-template/?params=" +
            urlEncodedParams;

        await axios
            .post(
                "/.netlify/functions/pdf-generate",
                JSON.stringify({
                    htmlPageUrl: htmlPageToConvert,
                    action: "email_pdf",
                    email_address: d["email-address"],
                    email_template_string: emailTemplateString,
                    filename: "My-Conversation-Plan.pdf",
                }),
                {
                    headers: {
                        "content-type":
                            "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                }
            )
            .then((res) => {
                setEmailSent(true);
                setEmailSending(false);
            })
            .catch((err) => {
                setEmailSending(false);
                throw new Error(err);
            });
    };

    const downloadPdf = async () => {
        setPdfDownloading(true);
        let finalData = {};
        Q1.forEach((item, index) => {
            finalData[`Q1_A${index + 1}`] = data["step-1-q-1"].includes(item);
        });
        Q5.forEach((item, index) => {
            finalData[`Q5_A${index + 1}`] = data["step-2-q-1"].includes(item);
        });
        Q8.forEach((item, index) => {
            finalData[`Q8_A${index + 1}`] = data["step-3-q-1"].includes(item);
        });
        finalData.Q2_A = data["step-1-q-2"];
        finalData.Q3_A = data["step-1-q-3"];
        finalData.Q4_A = data["step-1-q-4"];
        finalData.Q6_A = data["step-2-q-2"];
        finalData.Q7_A = data["step-2-q-3"];
        finalData = { ...finalData, Q1_A4_other, Q5_A7_other };

        const urlEncodedParams = encodeURIComponent(JSON.stringify(finalData));
        const htmlPageToConvert =
            window.location.origin +
            "/discussion-guide/pdf-template/?params=" +
            urlEncodedParams;

        await axios
            .post(
                "/.netlify/functions/pdf-generate",
                JSON.stringify({
                    htmlPageUrl: htmlPageToConvert,
                    action: "download_pdf",
                    email_address: "",
                    filename: "My-Conversation-Plan.pdf",
                }),
                {
                    headers: {
                        "content-type":
                            "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                }
            )
            .then((res) => {
                if (typeof window !== "undefined") {
                    setPdfDownloading(false);

                    const linkSource = `data:application/octet-stream;base64,${res.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "My-Conversation-Plan.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            })
            .catch((err) => {
                setPdfDownloading(false);
                throw new Error(err);
            });
    };
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    React.useEffect(() => {
        //console.log("htmlStrin is,", htmlString);
        console.log("host is,", window.location.origin);
    }, []);
    return (
        <HcpLayout
            pageClass="personalize-page orphan-page"
            jobCode="US-WAK-2300496/Dec 2023"
            isGhostPage="true"
        >
            <section className="cta-block" id="#">
                <section
                    className={`start ${step === 0 ? "current-step" : ""}`}
                >
                    <div className="top-title centered-content">
                        <h1 className="font-40">
                            Personalize Your Conversation
                        </h1>
                    </div>
                    <div className="first-content">
                        <div className="centered-content">
                            <Row className="row">
                                <Col lg={9}>
                                    <p>
                                        Everyone has unique needs when it comes
                                        to living with excessive daytime
                                        sleepiness (EDS) or cataplexy in
                                        narcolepsy. Help your healthcare
                                        provider understand your needs by
                                    </p>
                                    <ul className="bulleted">
                                        <li>Answering a few quick questions</li>
                                        <li>
                                            Getting a personalized conversation
                                            plan
                                        </li>
                                        <li>
                                            Using your conversation plan at your
                                            next appointment
                                        </li>
                                    </ul>
                                    <div className="btn-wrap">
                                        <button
                                            className="btn btn-primary normal"
                                            onClick={() => handleNextStep(1)}
                                        >
                                            Get started
                                            <TfiAngleRight />
                                        </button>
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="media">
                                        <img
                                            src={ddgArt}
                                            alt="art"
                                            className="img-fluid"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </section>

                <div
                    className={`steps-wrapper ${
                        step > 0 && step < 4 ? "" : "hide"
                    }`}
                >
                    <div className="centered-content">
                        <ul className="pager">
                            <li>
                                <span
                                    className={`circle ${
                                        step >= 1 ? "active" : ""
                                    }`}
                                ></span>
                            </li>
                            <li>
                                <span
                                    className={`circle ${
                                        step >= 2 ? "active" : ""
                                    }`}
                                ></span>
                            </li>
                            <li>
                                <span
                                    className={`circle ${
                                        step >= 3 ? "active" : ""
                                    }`}
                                ></span>
                            </li>
                        </ul>
                    </div>

                    <section
                        className={`step step-1 ${
                            step === 1 ? "current-step" : ""
                        }`}
                    >
                        <div className="centered-content">
                            <h2>My Life With Narcolepsy</h2>
                            <p>
                                Answer the following questions about your
                                experience with excessive daytime sleepiness
                                (EDS) or cataplexy in narcolepsy to personalize
                                your conversation.
                            </p>
                        </div>
                        <div
                            className={`question-wrapper gray-bg ${
                                errors1["step-1-q-1"] ? "error-show" : ""
                            }`}
                            attr-type="checkbox"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <div className="checkbox_set">
                                    <p className="question">
                                        In my day-to-day life, EDS or cataplexy
                                        in narcolepsy{" "}
                                        <span>(Check all that apply.)</span>
                                    </p>
                                    {Q1.map((item, index) => (
                                        <fieldset
                                            className="checkbox_field"
                                            key={item}
                                        >
                                            <label
                                                for={`step-1-q-1-${index + 1}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`step-1-q-1-${
                                                        index + 1
                                                    }`}
                                                    {...register1(
                                                        "step-1-q-1",
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                    value={item}
                                                />
                                                <span className="noselect">
                                                    {item}
                                                </span>
                                            </label>
                                        </fieldset>
                                    ))}
                                    <div
                                        className={`other-text ${
                                            watch1("step-1-q-1").includes(
                                                "Other"
                                            )
                                                ? "show"
                                                : ""
                                        } `}
                                    >
                                        <textarea
                                            className="form-control"
                                            onChange={(e) =>
                                                setQ1_A4_other(e.target.value)
                                            }
                                            id="step-1-q-1-4-other"
                                            rows="3"
                                            placeholder="(Describe here)"
                                            maxlength="150"
                                        ></textarea>
                                        <div className="char-counter">
                                            <span>0</span>/150
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`question-wrapper ${
                                errors1["step-1-q-2"] ? "error-show" : ""
                            }`}
                            attr-type="radio"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <Controller
                                    control={control1}
                                    name="step-1-q-2"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div
                                            className="radio_set"
                                            onChange={(e) =>
                                                onChange(e.target.value)
                                            }
                                        >
                                            <p className="question">
                                                How much does EDS or cataplexy
                                                in narcolepsy impact your
                                                day-to-day life?
                                            </p>
                                            <fieldset className="radio">
                                                <label for="step-1-q-2-1">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-2-1"
                                                        name="step-1-q-2"
                                                        value="Not at all"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Not at all
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-1-q-2-2">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-2-2"
                                                        name="step-1-q-2"
                                                        value="Somewhat"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Somewhat
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-1-q-2-3">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-2-3"
                                                        name="step-1-q-2"
                                                        value="A lot"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        A lot
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div
                            className={`question-wrapper gray-bg ${
                                errors1["step-1-q-3"] ? "error-show" : ""
                            }`}
                            attr-type="text"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please provide a response.
                                </p>
                                <div className="checkbox_set">
                                    <p className="question">
                                        What is your top goal for managing your
                                        EDS or cataplexy in narcolepsy?
                                    </p>
                                    <p className="mb-1">
                                        Type your response in the box below. For
                                        example:
                                    </p>
                                    <ul className="bulleted">
                                        <li>
                                            Take fewer naps throughout the day
                                        </li>
                                        <li>
                                            Stay awake to watch a movie after
                                            dinner with my family
                                        </li>
                                        <li>
                                            Feel more free to express my
                                            emotions in social situations
                                        </li>
                                    </ul>
                                    <textarea
                                        className="form-control"
                                        id="step-1-q-3"
                                        {...register1("step-1-q-3", {
                                            required: true,
                                        })}
                                        rows="3"
                                        placeholder="(Describe here)"
                                        maxlength="150"
                                    ></textarea>
                                    <div className="char-counter">
                                        <span>0</span>/150
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`question-wrapper ${
                                errors1["step-1-q-4"] ? "error-show" : ""
                            }`}
                            attr-type="radio"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <Controller
                                    control={control1}
                                    name="step-1-q-4"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div
                                            className="radio_set"
                                            onChange={(e) =>
                                                onChange(e.target.value)
                                            }
                                        >
                                            <p className="question">
                                                How often do you feel you reach
                                                this goal?
                                            </p>
                                            <fieldset className="radio">
                                                <label for="step-1-q-4-1">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-4-1"
                                                        group=""
                                                        name="step-1-q-4"
                                                        value="Almost always"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Almost always
                                                    </p>
                                                </label>
                                            </fieldset>
                                            <fieldset className="radio">
                                                <label for="step-1-q-4-2">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-4-2"
                                                        group=""
                                                        name="step-1-q-4"
                                                        value="Often"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Often
                                                    </p>
                                                </label>
                                            </fieldset>
                                            <fieldset className="radio">
                                                <label for="step-1-q-4-3">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-4-3"
                                                        group=""
                                                        name="step-1-q-4"
                                                        value="Sometimes"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Sometimes
                                                    </p>
                                                </label>
                                            </fieldset>
                                            <fieldset className="radio">
                                                <label for="step-1-q-4-4">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-4-4"
                                                        group=""
                                                        name="step-1-q-4"
                                                        value="Seldom"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Seldom
                                                    </p>
                                                </label>
                                            </fieldset>
                                            <fieldset className="radio">
                                                <label for="step-1-q-4-5">
                                                    <input
                                                        type="radio"
                                                        id="step-1-q-4-5"
                                                        group=""
                                                        name="step-1-q-4"
                                                        value="Never"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Never
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="centered-content">
                            <div class="btn-wrap">
                                <button
                                    class="btn btn-primary btn-primary--alt"
                                    onClick={() => handleBackStep(0)}
                                >
                                    <TfiAngleLeft className="left-icon" />
                                    Back
                                </button>
                                <button
                                    class="btn btn-primary"
                                    onClick={handleSubmit1((d) =>
                                        handleNextStep(2, d)
                                    )}
                                >
                                    Next
                                    <TfiAngleRight />
                                </button>
                            </div>
                        </div>
                    </section>

                    <section
                        className={`step step-2 ${
                            step === 2 ? "current-step" : ""
                        }`}
                    >
                        <div className="centered-content">
                            <h2>My Treatment Experience</h2>
                            <p>
                                Answer the following questions about your
                                treatment plan for excessive daytime sleepiness
                                (EDS) or cataplexy in narcolepsy to personalize
                                your conversation.
                            </p>
                        </div>

                        <div
                            className={`question-wrapper gray-bg ${
                                errors2["step-2-q-1"] ? "error-show" : ""
                            }`}
                            attr-type="checkbox"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <div className="checkbox_set">
                                    <p className="question">
                                        When it comes to my treatment plan, I am{" "}
                                        <span>(Check all that apply.)</span>
                                    </p>
                                    {Q5.map((item, index) => (
                                        <fieldset
                                            className="checkbox_field"
                                            key={item}
                                        >
                                            <label
                                                for={`step-2-q-1-${index + 1}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`step-2-q-1-${
                                                        index + 1
                                                    }`}
                                                    {...register2(
                                                        "step-2-q-1",
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                    value={item}
                                                />
                                                <span className="noselect">
                                                    {item}
                                                </span>
                                            </label>
                                        </fieldset>
                                    ))}
                                    <div
                                        className={`other-text ${
                                            watch2("step-2-q-1").includes(
                                                "Other"
                                            )
                                                ? "show"
                                                : ""
                                        } `}
                                    >
                                        <textarea
                                            className="form-control"
                                            id="step-2-q-1-7-other"
                                            onChange={(e) =>
                                                setQ5_A7_other(e.target.value)
                                            }
                                            rows="3"
                                            placeholder="(Describe here)"
                                            maxlength="150"
                                        ></textarea>
                                        <div className="char-counter">
                                            <span>0</span>/150
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`question-wrapper ${
                                errors2["step-2-q-2"] ? "error-show" : ""
                            }`}
                            attr-type="radio"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <Controller
                                    control={control2}
                                    name="step-2-q-2"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div
                                            className="radio_set"
                                            onChange={(e) =>
                                                onChange(e.target.value)
                                            }
                                        >
                                            <p className="question">
                                                How satisfied are you with your
                                                current treatment plan?
                                            </p>
                                            <fieldset className="radio">
                                                <label for="step-2-q-2-1">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-2-1"
                                                        group=""
                                                        name="step-2-q-2"
                                                        value="Not satisfied"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Not satisfied
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-2-q-2-2">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-2-2"
                                                        group=""
                                                        name="step-2-q-2"
                                                        value="Somewhat satisfied"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Somewhat satisfied
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-2-q-2-3">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-2-3"
                                                        group=""
                                                        name="step-2-q-2"
                                                        value="Very satisfied"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Very satisfied
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <div
                            className={`question-wrapper gray-bg ${
                                errors2["step-2-q-3"] ? "error-show" : ""
                            }`}
                            attr-type="radio"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <Controller
                                    control={control2}
                                    name="step-2-q-3"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <div
                                            className="radio_set"
                                            onChange={(e) =>
                                                onChange(e.target.value)
                                            }
                                        >
                                            <p className="question">
                                                How ready are you to consider a
                                                change to your treatment plan?
                                            </p>
                                            <fieldset className="radio">
                                                <label for="step-2-q-3-1">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-3-1"
                                                        group=""
                                                        name="step-2-q-3"
                                                        value="Ready"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Ready
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-2-q-3-2">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-3-2"
                                                        group=""
                                                        name="step-2-q-3"
                                                        value="Somewhat ready"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Somewhat ready
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-2-q-3-3">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-3-3"
                                                        group=""
                                                        name="step-2-q-3"
                                                        value="Not ready"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Not ready
                                                    </p>
                                                </label>
                                            </fieldset>

                                            <fieldset className="radio">
                                                <label for="step-2-q-3-4">
                                                    <input
                                                        type="radio"
                                                        id="step-2-q-3-4"
                                                        group=""
                                                        name="step-2-q-3"
                                                        value="Unsure"
                                                    />
                                                    <p className="noselect">
                                                        <span className="radio_span"></span>
                                                        Unsure
                                                    </p>
                                                </label>
                                            </fieldset>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="centered-content">
                            <div class="btn-wrap">
                                <button
                                    class="btn btn-primary btn-primary--alt"
                                    onClick={() => handleBackStep(1)}
                                >
                                    <TfiAngleLeft className="left-icon" />
                                    Back
                                </button>
                                <button
                                    class="btn btn-primary"
                                    onClick={handleSubmit2((d) =>
                                        handleNextStep(3, d)
                                    )}
                                >
                                    Next
                                    <TfiAngleRight />
                                </button>
                            </div>
                        </div>
                    </section>

                    <section
                        className={`step step-3 ${
                            step === 3 ? "current-step" : ""
                        }`}
                    >
                        <div className="centered-content">
                            <h2>My Questions About WAKIX</h2>
                            <p>
                                Talk to your healthcare provider to learn more
                                about WAKIX.
                            </p>
                        </div>

                        <div
                            className={`question-wrapper gray-bg ${
                                errors3["step-3-q-1"] ? "error-show" : ""
                            }`}
                            attr-type="checkbox"
                        >
                            <div className="centered-content">
                                <p className="error">
                                    Please make a selection.
                                </p>
                                <div className="checkbox_set">
                                    <p className="question">
                                        Select the questions you want to ask at
                                        your next appointment:
                                        <br />
                                        <span>(Check all that apply.)</span>
                                    </p>
                                    {Q8.map((item, index) => (
                                        <fieldset
                                            key={item}
                                            className="checkbox_field"
                                        >
                                            <label
                                                for={`step-3-q-1-${index + 1}`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`step-3-q-1-${
                                                        index + 1
                                                    }`}
                                                    {...register3(
                                                        "step-3-q-1",
                                                        {
                                                            required: true,
                                                        }
                                                    )}
                                                    value={item}
                                                />
                                                <span className="noselect">
                                                    {item}
                                                </span>
                                            </label>
                                        </fieldset>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="centered-content">
                            <div class="btn-wrap">
                                <button
                                    class="btn btn-primary btn-primary--alt"
                                    onClick={() => handleBackStep(2)}
                                >
                                    <TfiAngleLeft className="left-icon" />
                                    Back
                                </button>
                                <button
                                    class="btn btn-primary"
                                    onClick={handleSubmit3((d) =>
                                        handleNextStep(4, d)
                                    )}
                                >
                                    Get My Conversation Plan
                                    <TfiAngleRight />
                                </button>
                            </div>
                        </div>
                    </section>

                    <section
                        class={`step step-4 summary ${
                            step === 4 ? "current-step" : ""
                        }`}
                    >
                        <div className="centered-content">
                            <h2>Your Conversation Plan</h2>
                            <p>
                                Below you'll find a summary of your responses.
                                Use them as a guide during your next
                                conversation with your healthcare provider.
                            </p>
                            <p>
                                You can also{" "}
                                <a href="#summary-actions" class="scroll blue">
                                    <strong>
                                        <u>download</u>
                                    </strong>
                                </a>{" "}
                                your plan to save it.
                            </p>
                        </div>
                        <div className="step4-wrap gray-bg">
                            <div className="centered-content">
                                <h3>
                                    My Life With EDS or Cataplexy in Narcolepsy
                                </h3>
                                <div class="question-wrapper">
                                    <p>
                                        In my day-to-day life, EDS or cataplexy
                                        in narcolepsy
                                    </p>
                                    <div id="step-1-q-1-response">
                                        <ul class="bulleted">
                                            {data["step-1-q-1"]?.map(
                                                (item) =>
                                                    item !== "Other" && (
                                                        <li key={item}>
                                                            {item}
                                                        </li>
                                                    )
                                            )}
                                            {Q1_A4_other && (
                                                <li>{Q1_A4_other}</li>
                                            )}
                                        </ul>
                                    </div>

                                    <div
                                        id="step-1-q-2-response"
                                        class="bordered-blue"
                                    >
                                        <p class="pt-4 pl-2 mb-0">
                                            EDS or cataplexy in narcolepsy
                                            impacts my day-to-day life{" "}
                                            <strong class="blue text-tra-sm">
                                                {data["step-1-q-2"]}
                                            </strong>
                                            .
                                        </p>
                                    </div>

                                    <div class="bordered-blue">
                                        <p class="pt-4 pl-2 mb-0">
                                            My top goal for managing EDS or
                                            cataplexy in narcolepsy is
                                        </p>
                                        <p
                                            id="step-1-q-3-response"
                                            class="pl-2 pt-2"
                                        >
                                            {data["step-1-q-3"]}
                                        </p>
                                    </div>

                                    <div
                                        id="step-1-q-4-response"
                                        class="bordered-blue mt-4 mb-2"
                                    >
                                        <p class="pt-4 pl-2 mb-0">
                                            I feel I am
                                            <strong class="blue text-tra-sm">
                                                {" "}
                                                {data["step-1-q-4"]}{" "}
                                            </strong>
                                            reaching my goals for managing my
                                            EDS or cataplexy in narcolepsy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="step4-wrap">
                            <div className="centered-content">
                                <h3>My Treatment Experience</h3>
                                <div class="question-wrapper">
                                    <p>
                                        When it comes to my treatment plan, I am
                                    </p>
                                    <div id="step-2-q-1-response">
                                        <ul class="bulleted">
                                            {data["step-2-q-1"]?.map(
                                                (item) =>
                                                    item !== "Other" && (
                                                        <li key={item}>
                                                            {item}
                                                        </li>
                                                    )
                                            )}
                                            {Q5_A7_other && (
                                                <li>{Q5_A7_other}</li>
                                            )}
                                        </ul>
                                    </div>

                                    <div
                                        id="step-2-q-2-response"
                                        class="bordered-blue"
                                    >
                                        <p class="pt-4 pl-2 mb-0">
                                            I am
                                            <strong class="blue text-tra-sm">
                                                {" "}
                                                {data["step-2-q-2"]}{" "}
                                            </strong>
                                            with my current treatment plan.
                                        </p>
                                    </div>

                                    <div
                                        id="step-2-q-3-response"
                                        class="bordered-blue mt-4 mb-2"
                                    >
                                        <p class="pt-4 pl-2 mb-0">
                                            I am
                                            <strong class="blue text-tra-sm">
                                                {" "}
                                                {data["step-2-q-3"]}{" "}
                                            </strong>
                                            to consider making a change to my
                                            treatment plan for EDS or cataplexy
                                            in narcolepsy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="step4-wrap gray-bg">
                            <div className="centered-content">
                                <h3>My Questions About WAKIX</h3>
                                <div
                                    class="question-wrapper"
                                    id="summary-actions"
                                >
                                    <div id="step-3-q-1-response">
                                        <ul class="bulleted">
                                            {data["step-3-q-1"]?.map((item) => (
                                                <li class="active" key={item}>
                                                    {item}
                                                </li>
                                            ))}
                                            {Q8.map(
                                                (item) =>
                                                    !data[
                                                        "step-3-q-1"
                                                    ]?.includes(item) && (
                                                        <li
                                                            class="inactive"
                                                            key={item}
                                                        >
                                                            {item}
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="download-module">
                            <Row className="row">
                                <Col lg={4}>
                                    <div className="media">
                                        <img
                                            src={conversationImg}
                                            class="conversation-plan-thumb"
                                            alt="Conversion Plan"
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div class="btn-wrap btn-res">
                                        <button
                                            class="btn btn-primary dwnload"
                                            data-event-category="Personalize Your Conversation"
                                            data-event-action="Download"
                                            data-event-label="Conversation Plan"
                                            onClick={downloadPdf}
                                        >
                                            {pdfDownloading ? (
                                                <>
                                                    Downloading{" "}
                                                    <span className="spinner">
                                                        <ImSpinner />
                                                    </span>
                                                </>
                                            ) : (
                                                "Download"
                                            )}
                                            {!pdfDownloading && (
                                                <BsDownload size="20" />
                                            )}
                                        </button>
                                        <br />
                                        <button
                                            class="btn btn-primary"
                                            data-event-category="Personalize Your Conversation"
                                            data-event-action="Print"
                                            data-event-label="Conversation Plan"
                                            onClick={handlePrint}
                                        >
                                            Print
                                            <TfiAngleRight />
                                        </button>
                                        <br />
                                        <button
                                            class="btn btn-primary normal"
                                            data-toggle="collapse"
                                            onClick={handleEmailMyself}
                                            aria-expanded="false"
                                            aria-controls="collapse-email"
                                        >
                                            Email to myself
                                            <TfiAngleRight />
                                        </button>
                                        <br />
                                    </div>
                                </Col>
                            </Row>

                            <div>
                                <div
                                    class={`collapse collapse-email ${
                                        emailMyself ? "show" : ""
                                    } ${
                                        errors4["email-address"]
                                            ? "error-show"
                                            : ""
                                    } `}
                                    id="collapse-email"
                                >
                                    <div class="card card-body">
                                        <div>
                                            <fieldset
                                                class={`text_field ${
                                                    emailSent ? "" : "show"
                                                }`}
                                            >
                                                <label for="email">
                                                    <input
                                                        type="email"
                                                        id="email-address"
                                                        {...register4(
                                                            "email-address",
                                                            {
                                                                required: true,
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                    message:
                                                                        "Please enter a valid email",
                                                                },
                                                            }
                                                        )}
                                                        class="form-control"
                                                        placeholder="Email Address*"
                                                        aria-describedby="emailHelp"
                                                    />
                                                    <p className="error">
                                                        Please enter a correctly
                                                        formatted email.
                                                    </p>
                                                </label>
                                            </fieldset>
                                            <span
                                                className={`submitted-message ${
                                                    emailSent ? "show" : ""
                                                }`}
                                            >
                                                Email was successfully sent to:{" "}
                                                <span>
                                                    {watch4("email-address")}
                                                </span>
                                                .
                                            </span>
                                            <div
                                                class={`btn-wrap ${
                                                    emailSent ? "" : "show"
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-primary"
                                                    onClick={handleSubmit4(
                                                        (d) =>
                                                            handleSendEmail(d)
                                                    )}
                                                >
                                                    {emailSending ? (
                                                        <>
                                                            Emailing{" "}
                                                            <span className="spinner">
                                                                <ImSpinner />
                                                            </span>
                                                        </>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                    {!emailSending && (
                                                        <TfiAngleRight />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <div className="centered-content"></div>
            <div style={{ display: "none" }}>
                <PrintPdf
                    ref={componentRef}
                    data={data}
                    Q8={Q8}
                    others={{ Q1_A4_other, Q5_A7_other }}
                />
            </div>
        </HcpLayout>
    );
};

export default PersonalizePage;

export const Head = () => (
    <Seo
        title="Talking With Your Healthcare Provider | WAKIX® (pitolisant) tablets"
        description="Use this discussion guide to help prepare for your appointment with your healthcare provider."
        keywords=""
    />
);
