import React from "react";
import { Row, Col } from "react-grid-system";
import { Link } from "gatsby";
import FooterLogo from "../../images/footer-logo.png";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

import { triggerCustomGTM } from "../../utils/helper";

const globalFootText = (
  <>
   <p>
    WAKIX is a registered trademark of Bioprojet Pharma, Ltd.
    </p>
    <p>
    Harmony Biosciences name and logo are registered trademarks.
    </p>
    <p>
    &copy; 2023 Harmony Biosciences. All rights reserved.
    </p>
  </>
);

const PrintFooter = ({ footerText }) => {
  return (
    <div className="centered-content">
      <div className="footer-wrapper">
        <Row className="row">
          <Col lg={2} md={12}>
            <a
              href="https://www.harmonybiosciences.com/"
              rel="noopener noreferrer"
              target="_blank"
              className="footer-logo"
            >
              <img src={FooterLogo} alt="Harmony Biosciences logo" width="95" height="75" />
            </a>
          </Col>
          <Col lg={8}>
            <div className="footer-desc">
              <ul className="footer-menu">
                <li className="first">
                  <a
                    href="https://www.harmonybiosciences.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    About Harmony Biosciences
                  </a>
                </li>
                <li>
                  <Link to="/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link className="social-links">
                    Follow Us
                    <a
                      href="https://www.facebook.com/WAKIX"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="facebook-icon"
                      onClick={() =>
                        triggerCustomGTM({
                          event: "gtm.click",
                          customTag: "Follow Us Facebook",
                        })
                      }
                    >
                      <FaFacebookF alt="Follow us on Facebook icon" />
                    </a>
                    <a
                      href="https://www.instagram.com/wakix_pitolisant/"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="insta-icon"
                      onClick={() =>
                        triggerCustomGTM({
                          event: "gtm.click",
                          customTag: "Follow Us Instagram",
                        })
                      }
                    >
                      <FaInstagram alt="Follow us on Instagram icon" />
                    </a>
                  </Link>
                </li>
              </ul>
                {footerText ?? globalFootText}
                <br />
                US-WAK-2300496/Dec 2023
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function FooterPrint({ footerText, ...props }) {
  return (
    <footer {...props}>
      <PrintFooter footerText={footerText} />
    </footer>
  );
}

FooterPrint.propTypes = {};
