import React from "react";
import { Col, Row } from "react-grid-system";
import pageIcon from "../../images/discussion-guide/personalize/ddg-art.svg";
import wakixLogo from "../../images/wakix-logo.svg";
import FooterPrint from "./footer";
import "./print.scss";

import iconImg1 from "../../images/icon-1.png";
import iconImg2 from "../../images/icon-2.png";
import iconImg3 from "../../images/icon-3.png";
import HcpIsi from "../hcp/isi/isi-content";

const PrintPdf = React.forwardRef((props, ref) => {
  return (
    <div className="print-wrapper" ref={ref}>
      <div className="print-block">
        <div className="centered-content">
          <div className="page-content">
            <div className="media">
              <img src={pageIcon} className="header-icon" alt="Page Icon" />
              <div className="text-content">
                <h1>My Conversation Plan</h1>
                <p>
                  You answered a few questions about how you're managing
                  excessive daytime sleepiness (EDS) or cataplexy in narcolepsy.
                  Below you'll find a summary of your responses. {" "}
                  <strong>
                    Use them as a guide during your next conversation with your
                    healthcare provider.
                  </strong>
                </p>
              </div>
            </div>
            <div className="logo">
              <img src={wakixLogo} alt="Logo" />
            </div>
          </div>

          <div className="form-data">
            <Row>
              <Col lg={6}>
                <div className="content-wrap ht">
                  <div className="head-title">
                    <img src={iconImg1} alt="icon" />
                    <span>My Life With Narcolepsy</span>
                  </div>
                  <div className="form-list">
                    <div className="results">
                      <p className="head">
                        In my day-to-day life, EDS or cataplexy in narcolepsy
                      </p>
                      <div className="response">
                        <ul className="list">
                          {props.data["step-1-q-1"]?.map(
                            (item) =>
                              item !== "Other" && (
                                <li key={item} className="st-line">
                                  {item}
                                </li>
                              )
                          )}
                          {props.others?.Q1_A4_other && (
                            <li className="st-line">
                              {props.others?.Q1_A4_other}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How much does EDS or cataplexy in narcolepsy impact your
                        day-to-day life?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-1-q-2"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        What is your top goal for managing your EDS or cataplexy
                        in narcolepsy?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-1-q-3"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How often do you feel you reach this goal?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-1-q-4"]}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="content-wrap ht">
                  <div className="head-title">
                    <img src={iconImg2} alt="icon" />
                    <span>My Treatment Experience</span>
                  </div>
                  <div className="form-list">
                    <div className="results">
                      <p className="head">
                        When it comes to my treatment plan, I am
                      </p>
                      <div className="response">
                        <ul className="list">
                          {props.data["step-2-q-1"]?.map(
                            (item) =>
                              item !== "Other" && (
                                <li key={item} className="st-line">
                                  {item}
                                </li>
                              )
                          )}
                          {props.others?.Q5_A7_other && (
                            <li className="st-line">
                              {props.others?.Q5_A7_other}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How satisfied are you with your current treatment plan?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-2-q-2"]}</li>
                        </ul>
                      </div>
                    </div>
                    <div className="results">
                      <p className="head">
                        How ready are you to consider a change to your
                        treatment?
                      </p>
                      <div className="response">
                        <ul className="list">
                          <li>{props.data["step-2-q-3"]}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="checkbox-block form-data">
            <div className="content-wrap">
              <div className="head-title">
                <img src={iconImg3} alt="icon" />
                <span>
                  My Questions About WAKIX<sup>&reg;</sup> (pitolisant)
                </span>
              </div>
              <Row className="row">
                <Col xs={5}>
                  <div class="question-wrapper" attr-type="checkbox">
                    <div class="checkbox_set">
                      {props.Q8.map(
                        (item, index) =>
                          index < 3 &&
                          props.data["step-3-q-1"] && (
                            <fieldset
                              class={`checkbox_field ${
                                props.data["step-3-q-1"]?.includes(item)
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <label for="step-3-q-1-1">
                                <input
                                  type="checkbox"
                                  id="step-3-q-1-1"
                                  name="step-3-q-1[]"
                                  value={item}
                                />
                                <span class="noselect">{item}</span>
                              </label>
                            </fieldset>
                          )
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={7}>
                  <div class="question-wrapper" attr-type="checkbox">
                    <div class="checkbox_set">
                      {props.Q8.map(
                        (item, index) =>
                          index >= 3 &&
                          props.data["step-3-q-1"] && (
                            <fieldset
                              class={`checkbox_field ${
                                props.data["step-3-q-1"]?.includes(item)
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <label for="step-3-q-1-1">
                                <input
                                  type="checkbox"
                                  id="step-3-q-1-1"
                                  name="step-3-q-1[]"
                                  value={item}
                                />
                                <span class="noselect">{item}</span>
                              </label>
                            </fieldset>
                          )
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div>
        <section className="content-container isi-section">
  <div className="centered-content isi-content">
      <h2 className="isi-h2">Indications and Usage</h2>
      <p>
        WAKIX is a prescription medicine used to treat excessive daytime
        sleepiness (EDS) or sudden onset of weak or paralyzed muscles
        (cataplexy) in adults with narcolepsy.
      </p>
        <h2 className="isi-h2 normal">Important Safety Information</h2>
        <p>
        <strong>
          Do not take WAKIX if you are allergic to pitolisant or any ingredient in WAKIX, or if you have severe liver disease.
        </strong>
      </p>
      <p>
        <strong>
          Tell your healthcare provider about all your medical conditions, including if you have heart rhythm irregularities, were born with a heart condition, or the levels of electrolytes in your blood are too high or too low.
        </strong> WAKIX has an effect on the electrical activity of the heart known as QT prolongation. Medicines with this effect can lead to disturbances in heart rhythm, which are more likely in patients with risk factors such as certain heart conditions, or when taken in combination with other medicines that affect QT. Tell your healthcare provider about all the other medicines you take.
      </p>
      <p>
        The risk of QT prolongation may be greater in patients with liver or kidney disease. WAKIX is not recommended in patients with <nobr>end-stage</nobr> kidney disease.
      </p>
      <p>
        <strong>The most common side effects seen with WAKIX</strong> were insomnia, nausea, and anxiety. Other side effects included headache, upper respiratory tract infection, musculoskeletal pain, heart rate increased, and decreased appetite. These are not all the possible side effects of WAKIX. Tell your healthcare provider about any side effect that bothers you or that does not go away.
      </p>
      <p>
        <strong>
          Tell your healthcare provider about all the medicines you take or plan to take, including prescription and over-the-counter medicines.
        </strong> Some medicines can increase the amount of WAKIX that gets into your blood and some medicines can decrease the amount of WAKIX that gets into your blood. The dosage of WAKIX may need to be adjusted if you are taking these medicines.
      </p>
      <p>
        WAKIX can also decrease the effectiveness of some medicines, including hormonal birth control methods. You should use an alternative non-hormonal birth control method during treatment with WAKIX and for at least 21 days after discontinuation of treatment.
      </p>
      <p>
        <strong>
          Tell your healthcare provider if you are pregnant or planning to become pregnant.
        </strong> There is a pregnancy exposure registry that monitors pregnancy outcomes in women who are exposed to WAKIX during pregnancy. You are encouraged to enroll in the WAKIX pregnancy registry if you become pregnant while taking WAKIX. To enroll or obtain information from the registry,  call <nobr><a href="tel:1800FDA1088">1-800-833-7460</a></nobr>.
      </p>
      <p>
        <strong>
          The safety and effectiveness of WAKIX have not been established in patients less than 18 years of age.
        </strong>
      </p>
      <p>
        You are encouraged to report negative side effects of prescription drugs to the FDA. Visit <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" target="_blank" rel="noopener noreferrer">www.fda.gov/medwatch</a>, or call <a href="tel:1800FDA1088">1-800-FDA-1088</a>. You can also report negative side effects to Harmony Biosciences at <a href="tel:1800FDA1088">1-800-833-7460</a>.
      </p>
      <p className="strong">
        Please see accompanying <a href="/pdf/wakix-tablets-pi.pdf" target="_blank" rel="noopener noreferrer">Full Prescribing Information</a>.
      </p>
    </div>
</section>
        </div>
      </div>
      <div className="foot-bottom">
        <div className="foot-wrap">
          <FooterPrint />
        </div>
        <div className="foot-logo">
          <img src={wakixLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
});

export default PrintPdf;
